<template>
  <div>
    <!-- Filter Toolbar -->
    <v-toolbar v-if="location !== true" flat color="white" class="py-0">
      <!--  Filter Icon -->
      <v-menu
        v-if="location !== true"
        v-model="orderFilter"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-btn rounded color="primary" v-on="on">
            <v-icon small left> fal fa-filter </v-icon
            ><span
              v-if="
                ordersStore.filters.id ||
                  ordersStore.filters.customerID ||
                  ordersStore.filters.po ||
                  ordersStore.filters.status ||
                  ordersStore.filters.jobName
              "
              >Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="400px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <v-layout row wrap>
                <v-flex v-if="location !== true" xs12>
                  <v-btn
                    v-if="
                      ordersStore.filters.id ||
                        ordersStore.filters.customerID ||
                        ordersStore.filters.po ||
                        ordersStore.filters.status ||
                        ordersStore.filters.jobName
                    "
                    block
                    rounded
                    color="primary"
                    @click="clearFilter()"
                  >
                    <v-icon small left> fal fa-times </v-icon>Clear Filters
                  </v-btn>
                </v-flex>

                <v-flex xs12 v-if="usersStore.authUser.customers.length > 0">
                  <v-autocomplete
                    v-model="filterBar.customerID"
                    :items="usersStore.authUser.customers_view"
                    item-text="d_companyName"
                    item-value="id"
                    label="Customer"
                    placeholder=" "
                    persistent-placeholder
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="filterBar.id"
                    label="Order ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="filterBar.po"
                    label="Purchase Order"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="filterBar.jobName"
                    label="Job Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="filterBar.status"
                    :items="orderStatus"
                    item-text="name"
                    item-value="id"
                    label="Current Status"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn rounded block color="primary" @click="filter()">
              <v-icon small left> fal fa-check </v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!----------  Pagination Toolbar ---------->
    <pagination
      :show="
        ordersStore.orders.data &&
        ordersStore.orders.data.length > 0 &&
        ordersStore.orders.last_page > 1
          ? true
          : false
      "
      :current-page="ordersStore.orders.current_page"
      :last-page="ordersStore.orders.last_page"
      @paginate-event="filter($event)"
    />

    <!-- Order List -->
    <v-list v-if="ordersStore.orders.data.length > 0" two-line class="pt-0">
      <template v-for="(item, i) in ordersStore.orders.data">
        <v-list-item :key="i" @click="goOrder(item.uuid)">
          <v-list-item-content>
            <v-list-item-title
              v-if="usersStore.authUser.customers.length > 0"
              class="font-weight-bold"
            >
              {{ item.customer.d_companyName }}
            </v-list-item-title>

            <v-list-item-title>
              {{ item.d_orderNumber }} - {{ formatDate(item.d_dateOrder) }}
            </v-list-item-title>
            <v-list-item-title class="text--primary">
              {{ item.d_jobName }}
            </v-list-item-title>
            <v-list-item-subtitle
              >PO #{{ item.d_customerPurchaseOrder }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text
              v-if="item.f_hold === 1"
              class="subtitle-1 red--text font-weight-bold"
            >
              Hold
            </v-list-item-action-text>
            <v-list-item-action-text
              v-else
              class="text-xs-right secondary--text"
            >
              <span class="body-2"
                >Ship Date:{{ formatDate(item.d_dateDue) }}</span
              ><br />
              <span
                :class="
                  item.f_status === 1
                    ? 'subtitle-1 red--text font-weight-bold'
                    : 'subtitle-1 font-weight-bold'
                "
                ><div v-if="item.j_statusHistory">
                  <v-icon
                    v-if="
                      item.j_statusHistory.review.error ||
                        item.j_statusHistory.preProduction.error ||
                        item.j_statusHistory.goodsReceived.error ||
                        item.j_statusHistory.inProduction.error ||
                        item.j_statusHistory.shipped.error ||
                        item.j_statusHistory.invoiced.error
                    "
                    color="red"
                    >fas fa-exclamation-triangle</v-icon
                  >
                </div>
                {{ translateOrderStatus(item.f_status, item.f_download) }}</span
              >
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      </template>
    </v-list>

    <v-list v-else>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="red--text subtitle-1 text-xs-center">
            No Orders Exist
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import Pagination from "@/components/Pagination";

export default {
  name: "OrdersList",
  components: {
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      ordersStore: state => state.ordersStore
    })
  },
  props: {
    location: {
      type: Boolean
    }
  },
  data() {
    return {
      progress: "",
      orderFilter: false,
      filterBar: {
        id: "",
        customerID: "",
        po: "",
        jobName: "",
        status: "",
        page: ""
      },
      orderStatus: [
        {
          id: 1,
          name: "Canceled"
        },
        {
          id: 2,
          name: "Review"
        },
        {
          id: 3,
          name: "Pre-Production"
        },
        {
          id: 4,
          name: "Goods"
        },
        {
          id: 5,
          name: "Production"
        },
        {
          id: 6,
          name: "Invoiced"
        },
        {
          id: 7,
          name: "Shipped"
        },
        {
          id: 8,
          name: "Ready For Pick Up"
        },
        {
          id: 9,
          name: "Closed"
        },
        {
          id: 100,
          name: "All Open"
        },
        {
          id: 999,
          name: "Pending"
        }
      ]
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const filters = this.$store.getters["ordersStore/getFilters"];

      if (filters.manualFilter !== 1) {
        this.filterBar.id = "";
        this.filterBar.customerID = "";
        this.filterBar.po = "";
        this.filterBar.jobName = "";
        this.filterBar.status = "";
      } else {
        this.filterBar.id = filters.id;
        this.filterBar.customerID = filters.customerID;
        this.filterBar.po = filters.po;
        this.filterBar.jobName = filters.jobName;
        this.filterBar.status = filters.status;
      }
    },

    goOrder(uuid) {
      this.$router.push({ name: "orderDetail", params: { orderUUID: uuid } });
    },

    async filter(page) {
      this.orderFilter = false;

      const user = this.$store.getters["usersStore/getAuthUser"];

      let data;
      if (this.location) {
        data = {
          fk_customerID: user.fk_customerID,
          fk_userID: user.id,
          id: "",
          customerID: "",
          po: "",
          jobName: "",
          status: 100,
          page,
          manualFilter: 0,
          f_restrictOrdersByUser: user.f_restrictOrdersByUser
        };
      } else {
        data = {
          fk_customerID: user.fk_customerID,
          fk_userID: user.id,
          id: this.filterBar.id,
          customerID: this.filterBar.customerID,
          po: this.filterBar.po,
          jobName: this.filterBar.jobName,
          status: this.filterBar.status,
          page,
          manualFilter: 1,
          f_restrictOrdersByUser: user.f_restrictOrdersByUser
        };
      }

      this.$store.dispatch("ordersStore/storeFilters", data);
      await this.$store.dispatch("ordersStore/fetchByCustomer", data);
    },

    async clearFilter() {
      this.orderFilter = false;
      const user = this.$store.getters["usersStore/getAuthUser"];

      this.filterBar.id = "";
      this.filterBar.customerID = "";
      this.filterBar.po = "";
      this.filterBar.jobName = "";
      this.filterBar.status = "";
      this.filterBar.page = 1;

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        id: this.filterBar.id,
        customerID: this.filterBar.customerID,
        po: this.filterBar.po,
        jobName: this.filterBar.jobName,
        status: this.filterBar.status,
        page: this.filterBar.page,
        manualFilter: 0,
        f_restrictOrdersByUser: user.f_restrictOrdersByUser
      };
      this.$store.dispatch("ordersStore/storeFilters", data);
      await this.$store.dispatch("ordersStore/fetchByCustomer", data);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.resultContainer {
  height: 84%;
}
button {
  outline: none;
}
</style>
