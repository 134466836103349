<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <!--LEFT SIDE-->
        <v-flex xs12 lg12>
          <v-toolbar flat dense dark color="secondary">
            <v-icon>fal fa-shopping-cart</v-icon>
            <v-toolbar-title class="pl-2 pr-6">
              Open Orders
            </v-toolbar-title>
            <export-excel
              :section="2"
              :enabled="true"
              :export-title="'Open Orders Export'"
            />
            <v-spacer />
            <v-btn rounded color="green lighten-1" @click="newOrder()">
              <v-icon small left> fal fa-plus </v-icon>New
            </v-btn>
          </v-toolbar>

          <orders-list location />
        </v-flex>

        <!--RIGHT SIDE-->
        <v-flex v-if="1 === 2" xs12 lg2>
          <v-toolbar flat dense dark color="secondary">
            <v-icon>fal fa-heart-rate</v-icon>
            <v-toolbar-title class="pl-2">
              Activity
            </v-toolbar-title>
          </v-toolbar>

          <v-timeline align-top dense>
            <v-timeline-item
              v-for="(item, ii) in activityStore.activities"
              :key="ii"
              :color="item.user.d_avatarColor"
              medium
              fill-dot
            >
              <template v-slot:icon>
                <span class="white--text headline">{{
                  avatar(item.user.name)
                }}</span>
              </template>
              <v-layout pt-3>
                <v-flex>
                  <strong>{{ item.d_details }}</strong>
                  <div class="caption">
                    {{ formatTimestamp(item.created_at) }}
                  </div>
                </v-flex>
              </v-layout>
            </v-timeline-item>
          </v-timeline>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import OrdersList from "@/components/OrdersList";
import ExportExcel from "@/components/ExportExcel";

export default {
  name: "Dashboard",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      customersStore: state => state.customersStore,
      ordersStore: state => state.ordersStore,
      activityStore: state => state.activityStore
    })
  },
  components: {
    OrdersList,
    ExportExcel
  },
  data() {
    return {
      progress: false
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.progress = true;
      const user = this.$store.getters["usersStore/getAuthUser"];

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        const data = {
          fk_customerID: user.fk_customerID,
          fk_userID: user.id,
          id: "",
          po: "",
          jobName: "",
          status: 100,
          page: 1,
          manualFilter: 0,
          f_restrictOrdersByUser: user.f_restrictOrdersByUser
        };

        this.$store.dispatch("ordersStore/storeFilters", data);
        await this.$store.dispatch("customersStore/read", user.fk_customerID);
        await this.$store.dispatch("ordersStore/fetchByCustomer", data);
        await this.$store.dispatch("activityStore/fetchByCustomer", data);
        this.progress = false;
      }
    },

    goOrder(uuid) {
      this.$router.push({ name: "orderDetail", params: { orderUUID: uuid } });
    },

    newOrder() {
      this.$router.push({ name: "orderNew" });
    },

    activityType(type) {
      if (type === 1) {
        return "fal fa-shopping-cart";
      }
    },

    viewMessage(item) {
      this.message = { ...item };
      this.messageDialog = true;
    },

    async saveMessage() {
      if (!this.message.f_status) {
        await this.$store.dispatch("messagesStore/markRead", this.message);
      }
      this.messageDialog = false;
    }
  }
};
</script>

<style scoped>
button {
  outline: none;
}
</style>
